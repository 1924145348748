import CompanyStats from "@/components/companies/CompanyStats";
import NotFound from "@/components/entities/NotFound";
import ProfileDevButtons from "@/components/entities/ProfileDevButtons";
import ShowPipelineStatus from "@/components/entities/ShowPipelineStatus";
import DogfoodEntityFeedback from "@/components/feedback/DogfoodEntityFeedback";
import { useFeatureFlag } from "@/components/hooks/useFeatureFlag";
import Layout from "@/components/layout/Layout";
import PageLayout from "@/components/layout/PageLayout";
import FactEditModal from "@/components/modals/FactEditModal";
import AboutSection from "@/components/sections/AboutSection";
import CompanyFunding from "@/components/sections/CompanyFunding";
import CompanyPeople from "@/components/sections/CompanyPeople";
import EntityTOC from "@/components/sections/EntityTOC";
import EntityV2Header from "@/components/sections/EntityV2Header";
import MediaCoverage from "@/components/sections/MediaCoverage";
import MutualConnections from "@/components/sections/MutualConnections";
import NotableLinks from "@/components/sections/NotableLinks";
import SocialProfiles from "@/components/sections/SocialProfiles";
import SourcesV2 from "@/components/sections/SourcesV2";
import WorkEducation from "@/components/sections/WorkEducation";
import ErrorBoundary from "@/components/ui/ErrorBoundary";
import { useDevTools, useUI } from "@/hooks/useUIStore";
import { Attribute, EntityType, FeatureFlag, PipelineRunStatus, ServerProps } from "@/types";
import { Entity } from "@prisma/client";

type Props = {
  entity: Entity | undefined;
  attributes: Attribute[];
  status: PipelineRunStatus;
  headerButtons?: React.ReactNode;
  lastUpdated?: string;
} & ServerProps;

export default function EntityLayout({
  entity,
  attributes,
  status,
  lastUpdated,
  headerButtons,
  ...props
}: Props) {
  useUI(props);

  const isDev = useDevTools();

  if (!entity) return <NotFound />;

  return (
    <Layout title={entity.name}>
      <PageLayout outerClassName="bg-gray-50">
        <div className="flex flex-col sm:flex-row gap-4">
          <div>
            <ErrorBoundary>
              <div className="sticky top-4 flex flex-col gap-4">
                <EntityTOC />
                {isDev && <ProfileDevButtons entity={entity} />}
              </div>
              {isDev && <DogfoodEntityFeedback visible showMinimizedIcon />}
            </ErrorBoundary>
          </div>
          <div className="flex flex-col flex-1 mb-20">
            <EntityV2Header status={status} />
            <ShowPipelineStatus status={status} />
            <AboutSection />
            {entity.type === EntityType.Person && <MutualConnections />}
            {entity.type === EntityType.Company ?
              <>
                <CompanyStats />
                <CompanyPeople />
                <CompanyFunding />
              </>
            : <>
                <WorkEducation status={status} />
              </>
            }
            <SocialProfiles />
            <MediaCoverage />
            <NotableLinks />
            <SourcesV2 />
          </div>
          <FactEditModal />
        </div>
      </PageLayout>
    </Layout>
  );
}
