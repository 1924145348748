import CompanyStatsV3 from "@/components/companies/CompanyStatsV3";
import NotFound from "@/components/entities/NotFound";
import ProfileDevButtons from "@/components/entities/ProfileDevButtons";
import ShowPipelineStatus from "@/components/entities/ShowPipelineStatus";
import PastExperiences from "@/components/experiences/PastExperiences";
import DogfoodEntityFeedback from "@/components/feedback/DogfoodEntityFeedback";
import Layout from "@/components/layout/Layout";
import PageLayout from "@/components/layout/PageLayout";
import FactEditModal from "@/components/modals/FactEditModal";
import AboutSection from "@/components/sections/AboutSectionV3";
import CompanyFunding from "@/components/sections/CompanyFunding";
import CompanyPeople from "@/components/sections/CompanyPeople";
import EntityTOC from "@/components/sections/EntityTOC";
import EntityV3Header from "@/components/sections/EntityV3Header";
import MediaCoverageV3 from "@/components/sections/MediaCoverageV3";
import MutualConnections from "@/components/sections/MutualConnections";
import NotableLinksV3 from "@/components/sections/NotableLinksV3";
import SocialProfilesV2 from "@/components/sections/SocialProfiles";
import SourcesV3 from "@/components/sections/SourcesV3";
import ErrorBoundary from "@/components/ui/ErrorBoundary";
import { useDevTools, useUI } from "@/hooks/useUIStore";
import { Attribute, EntityType, GenericProfile, PipelineRunStatus, ServerProps } from "@/types";
import { Entity } from "@prisma/client";

type Props = {
  entity: Entity | undefined;
  attributes: Attribute[];
  status: PipelineRunStatus;
  headerButtons?: React.ReactNode;
  lastUpdated?: string;
  snapshot: GenericProfile;
} & ServerProps;

export default function EntityLayout({
  entity,
  attributes,
  status,
  lastUpdated,
  headerButtons,
  snapshot,
  ...props
}: Props) {
  useUI(props);

  const isDev = useDevTools();

  if (!entity || !snapshot) return <NotFound />;

  return (
    <Layout title={entity.name}>
      <PageLayout outerClassName="bg-gray-50">
        <div className="flex flex-col sm:flex-row gap-4">
          <div>
            <ErrorBoundary>
              <div className="sticky top-4 flex flex-col gap-4">
                <EntityTOC />
                {isDev && <ProfileDevButtons entity={entity} />}
              </div>
              {isDev && <DogfoodEntityFeedback visible showMinimizedIcon />}
            </ErrorBoundary>
          </div>
          <div className="flex flex-col flex-1 mb-20 overflow-x-hidden">
            <EntityV3Header status={status} />

            <ShowPipelineStatus status={status} />
            <AboutSection />
            {entity.type === EntityType.Person && <MutualConnections />}
            {entity.type === EntityType.Company && <CompanyStatsV3 />}
            {entity.type === EntityType.Company && <CompanyPeople />}
            {entity.type === EntityType.Company && <CompanyFunding />}
            {entity.type === EntityType.Person && <PastExperiences status={status} />}
            <SocialProfilesV2 />
            <MediaCoverageV3 />
            <NotableLinksV3 />
            <SourcesV3 />
          </div>
          <FactEditModal />
        </div>
      </PageLayout>
    </Layout>
  );
}
