import { GetServerSidePropsContext } from "next";

import { useUI } from "@/hooks/useUIStore";
import { FactSet } from "@/models/facts/facts.types";
import { loadEntity, loadWorkspaceData } from "@/server/loaders";
import { isRedirect, validateEntityPath } from "@/server/slugs";
import {
  Attribute,
  Entity,
  FeatureFlag,
  GenericProfile,
  PipelineRunStatus,
  ServerProps,
} from "@/types";
import { useEffect, useState } from "react";

import trackerWeb from "@/client/trackerWeb";
import EntityLayoutV2 from "@/components/entities/EntityV2Layout";
import EntityLayoutV3 from "@/components/entities/EntityV3Layout";

import NotFound from "@/components/entities/NotFound";
import { useFeatureFlag } from "@/components/hooks/useFeatureFlag";
import { entityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { EntityAlias } from "@prisma/client";
import { useSearchParams } from "next/navigation";
import errorTracker from "@/lib/errorTracker";

type Props = {
  entity: Entity | undefined;
  aliases?: string[];
  attributes: Attribute[];
  facts: FactSet;
  status: PipelineRunStatus;
  snapshot: GenericProfile;
  lastUpdated?: string;
} & ServerProps;

export default function EntityPage(props: Props) {
  const { entity, attributes, facts, status, snapshot, aliases, ...rest } = props;
  const [missingSnapshotErrorSent, setMissingSnapshotErrorSent] = useState(false);
  useUI(rest);
  useEffect(() => {
    if (entity) {
      void entityStore.load(entity, snapshot, { attributes, facts, aliases });
      trackerWeb.capture("view-entity", {
        entityId: entity.id,
        type: entity.type,
        name: entity.name,
      });
    }
  }, [entity, attributes, facts, snapshot, aliases]);

  useEffect(() => {
    if (status == PipelineRunStatus.IN_PROGRESS) {
      trackerWeb.capture("entity-in-progress", {
        entityId: entity?.id,
        type: entity?.type,
      });
    } else if (status != PipelineRunStatus.COMPLETED) {
      trackerWeb.capture("entity-status-" + status, {
        entityId: entity?.id,
        type: entity?.type,
      });
    }
  }, [entity, status]);

  const refreshParam = useSearchParams().get("refresh");

  useEffect(() => {
    uiStore.ingestUtmSource("entity-visit", { entityId: entity?.id });
    uiStore.getAndDeleteUrlParam("refresh");
  }, [entity?.id, refreshParam]);

  if (!entity) return <NotFound />;

  // this check makes sure only when the snapshot object exist do we load the layout
  if (snapshot && Object.keys(snapshot).length > 0) {
    return <EntityLayoutV3 {...props} />;
  } else {
    if (!missingSnapshotErrorSent) {
      errorTracker.sendError(new Error("Entity snapshot is empty, falling back to v2 layout"), {
        entityId: entity?.id,
        entityType: entity?.type,
        entitySlug: entity?.slug || "",
      });
      setMissingSnapshotErrorSent(true);
    }
    return <EntityLayoutV2 {...props} />;
  }
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const { session, redirect, ...rest } = await loadWorkspaceData(context);

  const pathname = context.resolvedUrl;
  const slugResult = await validateEntityPath(decodeURI(pathname), context.query.id as string);

  if (!slugResult) {
    return {
      notFound: true,
    };
  }

  if (isRedirect(slugResult)) return slugResult;

  const entityProps = await loadEntity(slugResult, session?.dbUser);
  return clearUndefined({
    props: {
      ...rest,
      ...entityProps,
    } as Props,
  });
};

/**
 * Next.js doesn't allow undefined values in props. This function removes them.
 * @param obj
 * @returns
 */
function clearUndefined<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}
