import moment from "moment";

/* eslint-disable @next/next/no-img-element */
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { favicon, prettyUrl, smartTruncate } from "@/lib/utils";
import { entityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { ProfilePageSection } from "@/types";
import { ArrowTopRightOnSquareIcon, StarIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import Link from "next/link";
import { Fragment } from "react";
import { twJoin } from "tailwind-merge";

export default withErrorBoundary(function SourcesV2() {
  const entity = useStore(entityStore.entity);
  const sources = useStore(entityStore.sources);
  const crawlResults = useStore(entityStore.crawlResults);
  const numberedSources = useStore(entityStore.numberedSources);
  const currentHash = useStore(uiStore.currentHash);
  const loading = useStore(entityStore.progress);

  const sourcesWithNumber = numberedSources
    .filter((s) => s) // remove empty url sources
    .map((s) => {
      const source = sources.find((ss) => ss.url === s) || {
        url: s,
        publishedAt: null,
        title: prettyUrl(s, true),
        summary: null,
        notes: null,
        description: s,
      };

      if (!source.title) {
        source.title = prettyUrl(s, true);
      }

      return source;
    });

  sourcesWithNumber.forEach((s) => {
    if (!s.publishedAt) {
      const result = crawlResults[s.url];
      if (result) {
        const d =
          result.publishDate ||
          (result.structuredData?.find((sd) => sd.datePublished)?.datePublished as
            | string
            | undefined);
        s.publishedAt = d ? moment(d).toDate() : null;
      }
    }
  });

  return (
    <ProfileBubble title="Sources" section={ProfilePageSection.Sources}>
      <div role="list" className="flex flex-col">
        {sourcesWithNumber.map((s, idx) => (
          <Fragment key={idx}>
            <div
              key={idx}
              className={twJoin(
                "flex gap-2 sm:gap-4 -mx-2 p-2 rounded-md",
                currentHash === `source${idx + 1}` && "bg-yellow-50",
              )}
            >
              <a id={`source${idx + 1}`} />
              {idx < sourcesWithNumber.length ?
                <div className="text-gray-400">{idx + 1}</div>
              : <div className="text-orange-300">
                  <StarIcon className="w-4 h-4" />
                </div>
              }
              <a
                href={s.url}
                title={s.url}
                target="_blank"
                className="flex flex-1 space-x-1 sm:space-x-3"
                rel="noreferrer"
              >
                <img
                  src={favicon({
                    url: s.url,
                    favicon: crawlResults[s.url]?.favicon,
                  })}
                  className="w-8 h-8 -mt-1 rounded border border-gray-100"
                  alt="favicon"
                  onError={(e) =>
                    ((e.target as HTMLImageElement).src =
                      favicon({
                        url: s.url,
                        favicon: null,
                      }) ?? "")
                  }
                />
                <div className="flex-1">
                  <div className="flex items-center">
                    <span className="font-semibold">{smartTruncate(s.title || "", 100)}</span>
                    <ArrowTopRightOnSquareIcon className="ml-2 h-4 w-4 text-gray-400" />
                    <span className="ml-2 text-gray-400">{prettyUrl(s.url, true)}</span>
                  </div>
                </div>
              </a>
              {s.publishedAt && (
                <div className="hidden sm:block text-gray-400">
                  {moment(s.publishedAt).format("ll")}
                </div>
              )}
            </div>

            <div className="mb-4 sm:ml-10">
              {s.description && (
                <div className="text-xs">
                  <div className="">{s.description}</div>
                </div>
              )}
              {s.summary && (
                <div className="text-xs">
                  <div className="text-gray-400 whitespace-pre-wrap">
                    <span className="font-semibold">Summary: </span>
                    {s.summary}
                  </div>
                </div>
              )}
              {s.notes && (
                <div className="text-xs">
                  <div className="text-gray-400 whitespace-pre-wrap">
                    <span className="font-semibold">Why it&apos;s relevant: </span>
                    {s.notes}
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        ))}
      </div>
      {!sourcesWithNumber.length && (
        <div className="text-gray-400 text-center">
          {loading ? "Sources still loading" : "No sources yet"}
        </div>
      )}
      <Link
        href={`${entity.slug}/sources`}
        className={twJoin("text-blue-600 hover:text-blue-800 p-4 -mb-4 block w-[100%] text-center")}
      >
        {sources.length ? `Manage All Sources (${sources.length})` : "Add Sources"}
      </Link>
    </ProfileBubble>
  );
});
