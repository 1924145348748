import { useState } from "react";
import { twMerge } from "tailwind-merge";

/* eslint-disable @next/next/no-img-element */
import { PlaceholderBackground } from "@/components/ui/PlaceholderBackground";
import { cachedImage } from "@/lib/utils";

export default function EmployeeCard({
  id,
  clickable,
  profileImage,
  placeholderIcon,
  name,
  title,
}: {
  id?: string;
  profileImage?: string;
  placeholderIcon?: JSX.Element;
  name?: string;
  title?: string;
  clickable?: boolean;
}) {
  const [showImage, setShowImage] = useState(true);

  return (
    <div className="h-full w-full">
      <div
        className={twMerge(
          "rounded-md relative border border-gray-100 bg-white w-full h-full overflow-hidden",
          clickable && `cursor-pointer hover:bg-blue-100`,
        )}
      >
        {showImage && profileImage?.startsWith("http") ?
          <img
            className="w-full h-48 object-cover"
            src={cachedImage(profileImage)}
            alt="Profile"
            onError={() => {
              setShowImage(false);
            }}
          />
        : placeholderIcon ?
          <PlaceholderBackground
            entity={{ name: name || "", id: id || "" }}
            className="w-full h-48 flex justify-center items-center"
          >
            {placeholderIcon}
          </PlaceholderBackground>
        : null}

        <div className="p-4">
          <div className="flex justify-between items-center mb-1">
            <h3 className="font-semibold text-md">{name}</h3>
          </div>
          {title && (
            <div className="text-gray-600 text-xs mb-1 whitespace-nowrap overflow-hidden text-ellipsis">
              {title}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
