/* eslint-disable @next/next/no-img-element */
import SourceTooltipLink, {
  getReferenceDataToShowFromURL,
} from "@/components/entities/SourceTooltipLink";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { formatDollarAmount } from "@/lib/stringUtils";
import { CompanyFact, FactType, FactValueSet } from "@/models/facts/facts.types";
import { entityStore } from "@/stores/entityStore";
import { FactPick, FactsPick, GenericProfile, ProfilePageSection } from "@/types";
import { useStore } from "@nanostores/react";
import { format, isValid, parseISO } from "date-fns";

export function buildStatsToShow(facts: FactsPick) {
  const statsToShow: FactType[] = [];
  if (facts[CompanyFact.Employees]) {
    statsToShow.push(CompanyFact.Employees);
  } else if (facts[CompanyFact.CompanySize]) {
    // employees is more fine-grained than company size
    statsToShow.push(CompanyFact.CompanySize);
  }

  const factTypes = [
    CompanyFact.FoundedYear,
    CompanyFact.TotalFunding,
    CompanyFact.NumExits,
    CompanyFact.NumFundsRaised,
    CompanyFact.NumInvestments,
    CompanyFact.TotalFundsRaisedAmt,
    CompanyFact.LatestFundSize,
    CompanyFact.LatestFundDate,
    CompanyFact.AssetsUnderManagement,
    CompanyFact.InvestmentRounds,
    CompanyFact.DoLeadInvestments,
    CompanyFact.InvestmentSectors,
    CompanyFact.InvestmentGeographies,
    CompanyFact.RecentInvestments,
    CompanyFact.FamousInvestments,
    CompanyFact.State,
  ];

  for (const factType of factTypes) {
    if (facts[factType]) {
      statsToShow.push(factType);
    }
  }

  return statsToShow;
}

export default withErrorBoundary(function CompanyStats() {
  const snapshot = useStore(entityStore.snapshot);

  if (!snapshot.facts) return null;
  const facts = snapshot.facts;

  const statsToShow = buildStatsToShow(facts);

  if (!statsToShow.length) {
    return null;
  }

  return (
    <ProfileBubble title="Details" section={ProfilePageSection.Stats}>
      <div className="flex flex-wrap justify-between">
        {statsToShow.map((type) => (
          <StatsBubble key={type} fact={facts[type]} type={type} snapshot={snapshot} />
        ))}
      </div>
    </ProfileBubble>
  );
});

function StatsBubble<T extends FactType>({
  fact,
  type,
  snapshot,
}: {
  fact: FactPick<T> | undefined;
  type: T;
  snapshot: GenericProfile;
}) {
  if (!fact) return null;

  const label = getLabel(type);
  const value = formatValue(type, fact.value);
  const sourceReference = getReferenceDataToShowFromURL(snapshot, fact.sourceUrl || undefined);
  if (!value || (Array.isArray(value) && value.length === 0)) return null;

  return (
    <div className="flex w-full lg:w-[45%] border-b-[1px] py-2 items-center">
      <div className="flex w-full flex-row items-center justify-between text-center">
        <div className="mt-1 text-md self-baseline">{label}</div>
        <div className="flex max-w-[50%] flex-wrap gap-x-1 justify-end font-semibold">
          {Array.isArray(value) ?
            value.map((val, index) => (
              <div
                key={index}
                className="text-sm leading-2 whitespace-nowrap overflow-hidden text-ellipsis"
              >
                {index > 0 && <span> • </span>}
                {typeof val === "object" ? JSON.stringify(val) : val}
              </div>
            ))
          : <div className="text-base">{value}</div>}
          {sourceReference && (
            <>
              <SourceTooltipLink title={sourceReference.url} href={sourceReference.citationUrl}>
                {sourceReference.idx}
              </SourceTooltipLink>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

function getLabel(type: FactType) {
  switch (type) {
    case CompanyFact.Employees:
      return "Employees";
    case CompanyFact.FoundedYear:
      return "Founded Year";
    case CompanyFact.Headquarters:
      return "Headquarters";
    case CompanyFact.CompanySize:
      return "Size";
    case CompanyFact.CompanyType:
      return "Type";
    case CompanyFact.TotalFunding:
      return "Total Funding";
    case CompanyFact.NumExits:
      return "Exits";
    case CompanyFact.NumFundsRaised:
      return "Funds Raised";
    case CompanyFact.NumInvestments:
      return "Investments";
    case CompanyFact.TotalFundsRaisedAmt:
      return "Total Funds Raised";
    case CompanyFact.InvestmentRounds:
      return "Investment Rounds";
    case CompanyFact.DoLeadInvestments:
      return "Lead Investor";
    case CompanyFact.RecentInvestments:
      return "Recent Investments";
    case CompanyFact.FamousInvestments:
      return "Famous Investments";
    case CompanyFact.InvestmentSectors:
      return "Sectors";
    case CompanyFact.InvestmentGeographies:
      return "Geographies";
    case CompanyFact.LatestFundSize:
      return "Latest Fund Size";
    case CompanyFact.LatestFundDate:
      return "Latest Fund Date";
    case CompanyFact.AssetsUnderManagement:
      return "Assets Under Management";
  }
  return type;
}

function formatValue<T extends FactType>(type: T, value: FactValueSet[T]) {
  switch (type) {
    case CompanyFact.InvestmentRounds:
    case CompanyFact.InvestmentGeographies:
      return value as string[];
    case CompanyFact.InvestmentSectors:
      return (value as string[]).filter((s) => s.length < 25).slice(0, 5);
    case CompanyFact.RecentInvestments:
    case CompanyFact.FamousInvestments:
      return (value as string[]).slice(0, 3);
    case CompanyFact.LatestFundSize:
    case CompanyFact.AssetsUnderManagement:
      return formatDollarAmount(value as number);
    case CompanyFact.LatestFundDate:
      const date = parseISO(value as string);
      if (!isValid(date)) {
        return "";
      }
      return format(date, "MMMM d, yyyy");
    case CompanyFact.DoLeadInvestments:
      return (
        value === undefined ? ""
        : value ? "Yes"
        : "No"
      );
    default:
      return value;
  }
}
