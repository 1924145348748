import trackerWeb from "@/client/trackerWeb";
import EntityCard from "@/components/ui/EntityCard";
import { EntityType } from "@/types";
import { UserIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import { useState } from "react";

export default function ConnectionCard({
  url,
  name,
  title,
  profileImage,
}: {
  url?: string;
  name: string;
  title: string;
  profileImage: string;
}): JSX.Element | null {
  if (name && url) {
    return (
      <>
        <div className="h-[65px]">
          <Link
            href={url}
            onClick={() => {
              trackerWeb.capture("show-mutual-connection", { url });
            }}
          >
            <EntityCard
              clickable
              id={url}
              type={EntityType.Person}
              profileImage={profileImage}
              placeholderIcon={<UserIcon className="h-10 w-10 text-white opacity-80" />}
              contextMenu={false}
              name={name}
              title={title || undefined}
              border={false}
              entityIconClassName="h-[50px] w-[50px] rounded"
              entityDetailsClassName="mt-0"
            />
          </Link>
        </div>
      </>
    );
  } else {
    return null;
  }
}
