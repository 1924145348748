import Button, { ButtonLink } from "@/components/ui/Button";
import { entityStore } from "@/stores/entityStore";
import {
  ArrowRightEndOnRectangleIcon,
  BoltIcon,
  CheckCircleIcon,
  ClipboardDocumentIcon,
  MagnifyingGlassIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useStore } from "@nanostores/react";
import { Entity } from "@prisma/client";
import { JsonArray } from "@prisma/client/runtime/library";
import { useState } from "react";

export default function ProfileDevButtons({ entity }: { entity: Entity }) {
  const [copied, setCopied] = useState(false);
  const [showPositives, setShowPositives] = useState(false);
  const [showNegatives, setShowNegatives] = useState(false);
  const [showQueries, setShowQueries] = useState(false);

  const entityQuery = useStore(entityStore.searchQuery);
  const aliases = useStore(entityStore.aliases);
  const snapshot = useStore(entityStore.snapshot);

  const copy = (id: string) => {
    setCopied(true);
    void navigator.clipboard.writeText(id);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div className="text-xs hidden sm:flex flex-col gap-2 w-48 justify-center">
      <ButtonLink
        className="bg-brand-100 hover:bg-brand-200 text-brand-500"
        href={`/admin/pipelines?entityId=${entity.id}`}
        target="_blank"
      >
        <span className="mr-2">🪓</span>
        Hatchet <span className="hidden sm:inline ml-1">Workflows</span>
      </ButtonLink>

      <ButtonLink
        className="bg-brand-100 hover:bg-brand-200 text-brand-500"
        href={`/admin/entity_history?entityId=${entity.id}`}
        target="_blank"
      >
        <span className="mr-2">📖</span>
        Entity <span className="hidden sm:inline ml-1">History</span>
      </ButtonLink>

      <Button
        className="bg-brand-100 hover:bg-brand-200 text-brand-500"
        data-tooltip-content={copied ? "Copied!" : "Copy"}
        onClick={() => copy(entity.id)}
      >
        <ClipboardDocumentIcon className="h-4 w-4 mr-2" />
        <span className="hidden sm:inline mr-1">Copy</span> Entity ID
      </Button>

      <Button
        className="bg-brand-100 hover:bg-brand-200 text-brand-500"
        onClick={() => {
          entityStore.mergeEntities();
        }}
      >
        <ArrowRightEndOnRectangleIcon className="h-4 w-4 mr-2" />
        Merge <span className="hidden sm:inline ml-1">Entities</span>
      </Button>

      <ButtonLink
        className="bg-brand-100 hover:bg-brand-200 text-brand-500"
        href={`${entity.slug}/qa`}
      >
        <span className="mr-2">🫡</span>
        QA <span className="hidden sm:inline ml-1">Checks</span>
      </ButtonLink>

      <ButtonLink
        className="bg-[#50fa7b] hover:bg-[#48E16E] text-black hidden sm:flex"
        href={`https://www.hyperdx.io/search?q=${entity.id}`}
        target="_blank"
      >
        <BoltIcon className="h-4 w-4 mr-2" />
        HyperDX Search
      </ButtonLink>

      <Button
        className="bg-red-300 hover:bg-red-500 text-black hover:text-white"
        onClick={() => entityStore.deleteEntity()}
      >
        <span className="mr-2 text-red-500">🚨</span>
        Delete <span className="hidden sm:inline ml-1">Entity</span>
      </Button>

      <div className="mt-4 mb-2">
        <div className="font-semibold mb-2">Entity URL</div>
        <div>{entity.url}</div>
        {aliases.length > 0 && <div className="font-semibold my-2">Aliases</div>}
        {aliases.map((alias) => (
          <div key={alias} className="break-words">
            {alias}
          </div>
        ))}
      </div>

      <div className="mt-4 mr-2">
        <h2 className="font-semibold mb-2">Profile Generation Inspection</h2>

        <div className="mb-2">
          <button
            className="text-left w-full bg-green-100 hover:bg-green-200 p-2 mb-2 rounded flex items-center"
            onClick={() => setShowPositives(!showPositives)}
          >
            <CheckCircleIcon className="h-4 w-4 mr-2" />
            Positive search filters
          </button>
          {showPositives && (
            <ul className="pl-4">
              {(snapshot.filters?.positives as JsonArray)?.map((filter, index) => (
                <li key={index} className="break-words mb-2">{`- ${filter?.toString()}`}</li>
              )) || <li>No saved filters</li>}
            </ul>
          )}
        </div>

        <div className="mb-2">
          <button
            className="text-left w-full bg-red-100 hover:bg-red-200 p-2 mb-2 rounded flex items-center"
            onClick={() => setShowNegatives(!showNegatives)}
          >
            <XCircleIcon className="h-4 w-4 mr-2" />
            Negative search filters
          </button>
          {showNegatives && (
            <ul className="pl-4">
              {(snapshot.filters?.negatives as JsonArray)?.map((filter, index) => (
                <li key={index} className="break-words mb-2">{`- ${filter?.toString()}`}</li>
              ))}

              {snapshot.filters?.negatives?.length === 0 && <li>No saved filters3</li>}
            </ul>
          )}
        </div>

        <div className="mb-2">
          <button
            className="text-left w-full bg-blue-100 hover:bg-blue-200 p-2 mb-2 rounded flex items-center"
            onClick={() => setShowQueries(!showQueries)}
          >
            <MagnifyingGlassIcon className="h-4 w-4 mr-2" />
            Search queries
          </button>
          {showQueries && (
            <ul className="pl-4">
              {(entityQuery?.queries as JsonArray)?.map((query, index) => (
                <li key={index} className="break-words mb-2">{`- ${query?.toString()}`}</li>
              )) || <li>No saved queries</li>}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
