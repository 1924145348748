import trackerWeb from "@/client/trackerWeb";
import ProfileBubble from "@/components/sections/ProfileBubble";
import EmployeeCard from "@/components/ui/EmployeeCard";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { CompanyFact, FactSet, PersonFact } from "@/models/facts/facts.types";
import { PersonCompanyRelationship } from "@/models/relationship/relationshipTypes";
import { entityStore } from "@/stores/entityStore";
import { EntityWithAttributes, ProfilePageSection } from "@/types";
import { entityUrl } from "@/utils/entityUtils";
import { UserIcon } from "@heroicons/react/20/solid";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useStore } from "@nanostores/react";
import { Entity } from "@prisma/client";
import { JsonObject } from "@prisma/client/runtime/library";
import { capitalize, groupBy } from "lodash";
import Link from "next/link";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const MAX_PEOPLE = 8;

export default withErrorBoundary(function CompanyPeople() {
  const entity = useStore(entityStore.entity);
  const relationships = useStore(entityStore.relationships);
  const linkedEntities = useStore(entityStore.linkedEntities);
  const facts = useStore(entityStore.facts);

  if (!relationships.length) return null;
  const uniqueRelationships = new Map<string, Entity>();

  relationships
    .filter(
      (relationship) =>
        (!relationship.endedDate || relationship.endedDate === "Present") &&
        relationship.type === PersonCompanyRelationship.WorkedAt &&
        relationship.toId === entity.id,
    )
    .sort((a, b) => {
      const aRank = (a.data as JsonObject)?.isImportant ? 1 : 0;
      const bRank = (b.data as JsonObject)?.isImportant ? 1 : 0;
      return bRank - aRank;
    })
    .forEach((relationship) => {
      if (relationship.fromId) {
        const entity = {
          ...linkedEntities[relationship.fromId],
          title:
            (relationship.data as JsonObject)?.title ?? linkedEntities[relationship.fromId].title,
        } as Entity;
        uniqueRelationships.set(relationship.fromId, entity);
      }
    });

  const allPeopleEntities = Array.from(uniqueRelationships.values());
  const peopleEntities = allPeopleEntities.slice(0, MAX_PEOPLE);

  const historicalHeadcount = facts[CompanyFact.HistoricalHeadcount]?.value;

  const groupedByYear = groupBy(historicalHeadcount, (item) => new Date(item.date).getFullYear());
  const aggregatedHeadcount = Object.entries(groupedByYear)
    .map(([year, data]) => ({
      date: year,
      headcount: Math.max(...data.map((d) => d.headcount)),
    }))
    .sort((a, b) => Number(a.date) - Number(b.date));

  if (peopleEntities.length === 0 && aggregatedHeadcount.length === 0) return null;
  const peopleTitle = `People ${aggregatedHeadcount.length > 0 ? `(${aggregatedHeadcount[aggregatedHeadcount.length - 1].headcount})` : ""}`;

  return (
    <ProfileBubble title={peopleTitle} section={ProfilePageSection.People}>
      <div className="mt-6 mb-2">
        {aggregatedHeadcount.length > 0 && (
          <ResponsiveContainer className="mt-4" width="100%" height={250}>
            <BarChart data={aggregatedHeadcount} margin={{ top: 5, right: 5, left: 5, bottom: 5 }}>
              <XAxis
                dataKey="date"
                tickFormatter={(tick) => new Date(tick as string).getFullYear().toString()}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value: number) => {
                  if (value >= 1000000) {
                    return `${(value / 1000000).toFixed(value % 1000000 !== 0 ? 2 : 0)}M`;
                  } else if (value >= 1000) {
                    return `${(value / 1000).toFixed(value % 1000 !== 0 ? 2 : 0)}k`;
                  } else {
                    return value.toString();
                  }
                }}
              />
              <Tooltip formatter={(value) => value.toLocaleString()} />
              <CartesianGrid vertical={false} />
              <Bar dataKey="headcount" fill="#8884d8" radius={[5, 5, 0, 0]} barSize={40} />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {peopleEntities.map((person) => {
          return (
            <Link
              href={entityUrl(person)}
              key={person.id}
              onClick={() => {
                trackerWeb.capture("show-company-person", {
                  companyId: entity.id,
                  personId: person.id,
                });
              }}
            >
              <EmployeeCard
                key={person.id}
                clickable
                id={person.id}
                name={person.name}
                profileImage={person.imageUrl || undefined}
                placeholderIcon={<UserIcon className="h-24 w-24 text-white opacity-80" />}
                title={person.title || undefined}
              />
            </Link>
          );
        })}
      </div>
      {/* 
        <div className="flex flex-col items-center mt-4">
          <hr className="w-full border-t border-gray-300 mb-4" />
          <Link
            href={`${entity.slug}/people`}
            className="flex items-center text-blue-400 font-medium"
          >
            <MagnifyingGlassIcon className="w-4 h-4 mr-2" />
            Search for more employees
          </Link>
        </div>
       */}
    </ProfileBubble>
  );
});
