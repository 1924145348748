import ProfileBubble from "@/components/sections/ProfileBubble";
import Loader from "@/components/ui/Loader";
import { entityStore } from "@/stores/entityStore";
import { ProfilePageSection } from "@/types";
import {
  AcademicCapIcon,
  BanknotesIcon,
  BookOpenIcon,
  BriefcaseIcon,
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
  DocumentDuplicateIcon,
  HandRaisedIcon,
  NewspaperIcon,
  PencilIcon,
  PhotoIcon,
  StarIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { twJoin } from "tailwind-merge";

const Sections = {
  [ProfilePageSection.Highlights]: { icon: StarIcon, title: "Highlights" },
  [ProfilePageSection.About]: { icon: UserIcon, title: "About" },
  [ProfilePageSection.MutualConnections]: { icon: UsersIcon, title: "Mutuals" },
  [ProfilePageSection.ForYou]: { icon: PencilIcon, title: "For You" },
  [ProfilePageSection.Stats]: { icon: ChartBarIcon, title: "Stats" },
  [ProfilePageSection.WorkHistory]: { icon: BriefcaseIcon, title: "Work History" },
  [ProfilePageSection.OtherExperience]: {
    icon: DocumentDuplicateIcon,
    title: "Other Exp",
  },
  [ProfilePageSection.Investments]: { icon: BanknotesIcon, title: "Investments" },
  [ProfilePageSection.People]: { icon: UsersIcon, title: "People" },
  [ProfilePageSection.Funding]: { icon: HandRaisedIcon, title: "Funding" },
  [ProfilePageSection.Education]: { icon: AcademicCapIcon, title: "Education" },
  [ProfilePageSection.SocialMedia]: { icon: ChatBubbleLeftRightIcon, title: "Social Media" },
  [ProfilePageSection.PhotosVideos]: { icon: PhotoIcon, title: "Photos & Videos" },
  [ProfilePageSection.MediaCoverage]: { icon: NewspaperIcon, title: "Media Coverage" },
  [ProfilePageSection.Connections]: { icon: UsersIcon, title: "Connections" },
  [ProfilePageSection.Sources]: { icon: BookOpenIcon, title: "Sources" },
};

export default function EntityTOC() {
  const allSections = Object.keys(Sections) as ProfilePageSection[];
  const visibleSections = useStore(entityStore.profileSections);
  const sectionItems = allSections
    .filter((s) => visibleSections[s])
    .map((section) => ({
      id: section,
      ...(Sections as any)[section],
      count: visibleSections[section],
    }));
  const selected = useStore(entityStore.currentSection);

  return (
    <div className="hidden lg:block">
      <ProfileBubble className="p-2 w-48 sticky top-4">
        <ul>
          {sectionItems.map((section) => (
            <li key={section.id}>
              <a
                href={"#" + section.id}
                className={twJoin(
                  "flex items-center space-x-2 hover:bg-brand-50 rounded-md p-2 text-gray-500",
                  section.id == selected && "bg-brand-50 text-brand-700 font-medium",
                )}
                onClick={() => {
                  entityStore.currentSection.set(section.id);
                }}
              >
                <section.icon className="h-4 w-4" />
                <span className="flex-1">{section.title}</span>
                {section.count == "loading" ?
                  <Loader className="w-3 h-3" />
                : typeof section.count === "number" ?
                  <span className="text-gray-400">{section.count}</span>
                : null}
              </a>
            </li>
          ))}
        </ul>
      </ProfileBubble>
    </div>
  );
}
