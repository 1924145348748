import { useState } from "react";
import { twMerge } from "tailwind-merge";

/* eslint-disable @next/next/no-img-element */
import Loader from "@/components/ui/Loader";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import PopoverMenu from "@/components/ui/PopoverMenu";
import { EntityType } from "@/types";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

export default function EntityCard({
  id,
  clickable,
  border = true,
  profileImage,
  type,
  placeholderIcon,
  name,
  title,
  url,
  contextMenu,
  children,
  loading,
  className,
  entityIconClassName,
  entityDetailsClassName,
}: {
  id?: string;
  profileImage?: string;
  type?: EntityType;
  loading?: boolean;
  placeholderIcon?: JSX.Element;
  name?: string;
  title?: string;
  url?: string;
  contextMenu?: JSX.Element | boolean;
  clickable?: boolean;
  border?: boolean;
  children?: React.ReactNode;
  className?: string;
  entityIconClassName?: string;
  entityDetailsClassName?: string;
}) {
  const [showImage, setShowImage] = useState(true);

  const buttonLabel =
    loading ?
      <Loader className="" />
    : <EllipsisVerticalIcon className="w-4 text-gray-400 ml-auto " />;

  return (
    <div className="h-full w-full">
      <div
        className={twMerge(
          "rounded-md relative border-gray-100 bg-white w-full h-full p-2",
          border && "border",
          clickable && `cursor-pointer hover:bg-blue-100`,
          className,
        )}
      >
        {contextMenu && (
          <PopoverMenu
            buttonClass="absolute top-2 right-2"
            buttonLabel={buttonLabel}
            popperOptions={{ placement: "bottom-end" }}
            keepOpenOnClick
          >
            {contextMenu}
          </PopoverMenu>
        )}
        {contextMenu === undefined && <div className="absolute top-2 right-2">{buttonLabel}</div>}

        <div className="flex items-center gap-4 max-h-full">
          {showImage && !loading && (
            <EntityIconWithPlaceholder
              className={twMerge(
                "h-[80px] w-[80px] shadow-inner overflow-hidden",
                entityIconClassName,
              )}
              imageClassName="w-full h-full"
              entity={{
                name: name || "",
                id: "",
                type: type || EntityType.Unknown,
                imageUrl: profileImage,
              }}
            />
          )}

          <div className="flex-1 flex flex-col overflow-hidden">
            <div
              className={twMerge(
                "overflow-hidden line-clamp-1 text-ellipsis mb-1 font-semibold",
                entityDetailsClassName,
              )}
            >
              {name}
            </div>
            {url && (
              <div
                className={twMerge(
                  "text-xs text-gray-500 line-clamp-1 text-ellipsis overflow-hidden mb-1",
                  !url.includes("@") && "text-brand-600",
                )}
              >
                {url}
              </div>
            )}
            {title !== undefined && (
              <div className="text-gray-500 text-xs overflow-hidden line-clamp-2 h-8 text-ellipsis max-w-[200px]">
                {title}
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
