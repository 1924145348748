import ConnectionCard from "@/components/entities/ConnectionCard";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import ExpandoList from "@/components/ui/ExpandoList";
import { entityStore } from "@/stores/entityStore";
import {
  MutualConnectionsStatus,
  PartialEntity,
  PrivateAttributeMutualConnection,
  ProfilePageSection,
} from "@/types";
import { entityUrl } from "@/utils/entityUtils";
import { useStore } from "@nanostores/react";
import { useEffect, useRef, useState } from "react";

type Props = {};

export default withErrorBoundary(function MutualConnections(props: Props) {
  const mutualConnections = useStore(entityStore.mutualConnections);
  const [connections, setConnections] = useState<PrivateAttributeMutualConnection[]>([]);
  const [unresolvedCount, setUnresolvedCount] = useState(0);
  const hasResolved = useRef(false);

  const resolve = async (start?: number, end?: number) => {
    return await entityStore.resolveMutualConnections(start, end);
  };

  const makeEntityUrl = (connection: PrivateAttributeMutualConnection) => {
    if (!connection) {
      return undefined;
    }
    const entity = mutualConnections[connection.linkedinUrl]?.entity as PartialEntity;
    return entity ?
        entityUrl(entity)
      : `/profile?url=${encodeURIComponent(connection.linkedinUrl)}`;
  };

  useEffect(() => {
    setConnections(Object.values(mutualConnections).map((connection) => connection.data));
    setUnresolvedCount(Object.values(mutualConnections).filter((c) => !c.resolved).length);
  }, [mutualConnections]);

  useEffect(() => {
    if (unresolvedCount > 0 && hasResolved.current) {
      hasResolved.current = false;
    }
  }, [unresolvedCount]);

  useEffect(() => {
    if (!hasResolved.current) {
      void resolve(0);
      // const start = 0;
      // const end = connections.length < 6 ? connections.length : 6;
      // void resolve(start, end);
      // void resolve(end);
      hasResolved.current = true;
    }
  }, [unresolvedCount, connections]);

  if (entityStore.mutualConnectionsStatus.get() === MutualConnectionsStatus.Pending) {
    return;
  }

  return (
    <ProfileBubble
      title={`Mutual Connections • ${String(connections.length)}`}
      section={ProfilePageSection.MutualConnections}
    >
      {connections.length === 0 && <div className="text-gray-500">No mutual connections found</div>}
      {connections.length > 0 && (
        <div className="flex flex-col gap-4 overflow-hidden">
          <ExpandoList
            items={connections}
            limit={4}
            divideExpander
            seeMoreClassName="pt-0 text-center"
            className="list-inside text-gray-700 gap-4 flex flex-col sm:grid sm:grid-cols-2"
            renderItem={(connection, index) => (
              <ConnectionCard
                key={connection?.linkedinUrl || index}
                name={
                  mutualConnections[connection?.linkedinUrl]?.entity?.name || connection?.name || ""
                }
                title={(mutualConnections[connection?.linkedinUrl]?.entity?.title || "").slice(
                  0,
                  50,
                )}
                url={makeEntityUrl(connection)}
                profileImage={
                  mutualConnections[connection?.linkedinUrl]?.entity?.imageUrl ||
                  connection?.profileImage ||
                  ""
                }
              />
            )}
          />
        </div>
      )}
    </ProfileBubble>
  );
});
