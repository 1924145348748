import moment from "moment";

/* eslint-disable @next/next/no-img-element */
import ProfileBubble from "@/components/sections/ProfileBubble";
import ExpandoList from "@/components/ui/ExpandoList";
import { favicon, smartTruncate } from "@/lib/utils";
import { entityStore } from "@/stores/entityStore";
import { CrawlResultSansBody, ProfilePageSection } from "@/types";
import { useStore } from "@nanostores/react";

export default function MediaCoverage() {
  const mediaArticles = useStore(entityStore.mediaArticles);
  if (!mediaArticles.length) return null;

  mediaArticles.forEach((s) => {
    if (!s.publishDate)
      s.publishDate = s.structuredData?.find((sd) => sd.datePublished)?.datePublished as
        | string
        | undefined;
  });

  const sorted = mediaArticles.sort((a, b) => {
    if (a.publishDate && b.publishDate) return b.publishDate.localeCompare(a.publishDate);
    return 0;
  });

  return (
    <ProfileBubble title="Media Coverage" section={ProfilePageSection.MediaCoverage}>
      <div role="list" className="flex flex-col gap-4">
        <ExpandoList
          items={sorted}
          renderItem={(s, idx) => (
            <div key={idx}>
              <a
                href={s.url}
                target="_blank"
                className="flex space-x-1 sm:space-x-3"
                rel="noreferrer"
              >
                <img
                  src={favicon({
                    url: s.url,
                    favicon: s.favicon,
                  })}
                  className="w-8 h-8 mt-1 rounded-md border border-gray-100"
                  alt="favicon"
                  onError={(e) => ((e.target as HTMLImageElement).style.display = "none")}
                />

                <div>
                  <div className="font-semibold line-clamp-1">{s.title}</div>
                  <div className="text-gray-400 text-sm">
                    {s.publishDate && moment(s.publishDate).format("ll")}
                    <Author s={s} />
                  </div>
                </div>
              </a>
            </div>
          )}
        />
      </div>
    </ProfileBubble>
  );
}

function Author({ s }: { s: CrawlResultSansBody }) {
  const authorTag =
    s.structuredData?.find((sd) => sd.author) || s.structuredData?.find((s) => s.publisher);

  if (!authorTag) return null;
  const authorObject = authorTag.author || authorTag.publisher;

  const authorName =
    typeof authorObject === "string" ? authorObject
    : Array.isArray(authorObject) ?
      (authorObject as { name: string }[]).map((a) => a.name).join(", ")
    : authorObject && typeof authorObject == "object" ? authorObject.name
    : null;

  if (!authorName) return null;
  return ` by ${smartTruncate(String(authorName), 100)}`;
}
