import moment from "moment";

/* eslint-disable @next/next/no-img-element */
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { favicon, prettyUrl, smartTruncate } from "@/lib/utils";
import { entityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { ProfilePageSection, SourceIsRight } from "@/types";
import { ArrowTopRightOnSquareIcon, StarIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { Fragment, useState } from "react";
import { twJoin } from "tailwind-merge";

export default withErrorBoundary(function NotableLinks() {
  const [expanded, setExpanded] = useState(false);

  const sources = useStore(entityStore.sources);
  const crawlResults = useStore(entityStore.crawlResults);
  const numberedSources = useStore(entityStore.numberedSources);
  const currentHash = useStore(uiStore.currentHash);
  const loading = useStore(entityStore.progress);

  const numberedSourcesSet = new Set(numberedSources);

  const mediaArticles = new Set(useStore(entityStore.mediaArticles).map((s) => s.url));
  const highlightedSources = sources
    .filter(
      (s) =>
        s.starred &&
        s.isRight != SourceIsRight.No &&
        !mediaArticles.has(s.url) && // TODO: Do we want to filter out media articles from notable links?
        !numberedSourcesSet.has(s.url),
    )
    .slice(0, 5);

  highlightedSources.forEach((s) => {
    if (!s.publishedAt) {
      const result = crawlResults[s.url];
      if (result) {
        const d =
          result.publishDate ||
          (result.structuredData?.find((sd) => sd.datePublished)?.datePublished as
            | string
            | undefined);
        s.publishedAt = d ? moment(d).toDate() : null;
      }
    }
  });

  const displayedSources = expanded ? highlightedSources : highlightedSources.slice(0, 3);

  if (!displayedSources.length) {
    return null;
  }

  return (
    <ProfileBubble title="Notable Links" section={ProfilePageSection.Sources}>
      <div role="list" className="flex flex-col">
        {displayedSources.map((s, idx) => (
          <Fragment key={idx}>
            <div
              key={idx}
              className={twJoin(
                "flex gap-2 sm:gap-4 -mx-2 p-2 rounded-md",
                currentHash === `source${idx + 1}` && "bg-yellow-50",
              )}
            >
              <div className="text-orange-300">
                <StarIcon className="w-4 h-4" />
              </div>

              <a
                href={s.url}
                title={s.url}
                target="_blank"
                className="flex flex-1 space-x-1 sm:space-x-3"
                rel="noreferrer"
              >
                <img
                  src={favicon({
                    url: s.url,
                    favicon: crawlResults[s.url]?.favicon,
                  })}
                  className="w-8 h-8 -mt-1 rounded-md border border-gray-100"
                  alt="favicon"
                  onError={(e) =>
                    ((e.target as HTMLImageElement).src =
                      favicon({
                        url: s.url,
                        favicon: null,
                      }) ?? "")
                  }
                />
                <div className="flex-1">
                  <div className="flex items-center">
                    <span className="font-semibold">{smartTruncate(s.title || "", 100)}</span>
                    <ArrowTopRightOnSquareIcon className="ml-2 h-4 w-4 text-gray-400" />
                    <span className="ml-2 text-gray-400">{prettyUrl(s.url, true)}</span>
                  </div>
                </div>
              </a>
              {s.publishedAt && (
                <div className="hidden sm:block text-gray-400">
                  {moment(s.publishedAt).format("ll")}
                </div>
              )}
            </div>

            <div className="mb-4 sm:ml-10">
              {s.description && (
                <div className="text-xs">
                  <div className="">{s.description}</div>
                </div>
              )}
              {s.summary && (
                <div className="text-xs">
                  <div className="text-gray-400 whitespace-pre-wrap">
                    <span className="font-semibold">Summary: </span>
                    {s.summary}
                  </div>
                </div>
              )}
              {s.notes && (
                <div className="text-xs">
                  <div className="text-gray-400 whitespace-pre-wrap">
                    <span className="font-semibold">Why it&apos;s relevant: </span>
                    {s.notes}
                  </div>
                </div>
              )}
            </div>
          </Fragment>
        ))}
      </div>
      {!highlightedSources.length && (
        <div className="text-gray-400 text-center">
          {loading ? "Sources still loading" : "No sources yet"}
        </div>
      )}
      {highlightedSources.length > 3 && (
        <button
          className={twJoin(
            "text-blue-600 hover:text-blue-800 p-4 -mb-4 block w-[100%] text-center",
          )}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "Hide" : `See ${highlightedSources.length - 3} more`}
        </button>
      )}
    </ProfileBubble>
  );
});
